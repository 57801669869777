#footer {
  margin-top: 46px;
}

#footer .f_wrap a {
  transition-duration: .2s;
}

#footer .f_wrap .f_menu {
  width: 1046px;
  margin: 0 auto 90px;
  display: flex;
  justify-content: space-between;
  padding: 50px 0 0 46px;
  border-top: 1px solid #000;
}

#footer .f_wrap .f_menu a:hover {
  opacity: 1;
  color: #c02d28;
}

#footer .f_wrap .f_menu .f_menu_blk {
  width: 180px;
}

#footer .f_wrap .f_menu .f_menu_blk:nth-of-type(2) {
  width: 200px;
}

#footer .f_wrap .f_menu .f_menu_blk:nth-of-type(3) {
  width: 150px;
}

#footer .f_wrap .f_menu .f_menu_blk:nth-of-type(4) {
  width: 140px;
}

#footer .f_wrap .f_menu .f_menu_blk:nth-of-type(5) {
  width: 210px;
}

#footer .f_wrap .f_menu .f_menu_blk p {
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: .15em;
  line-height: 1;
  margin-bottom: 14px;
}

#footer .f_wrap .f_menu .f_menu_blk ul li {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: .05em;
}

#footer .f_wrap .f_bottom {
  width: 100%;
  background-color: #eeeeee;
  padding: 30px 0 26px;
}

#footer .f_wrap .f_bottom .f_bottom_wrap {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

#footer .f_wrap .f_bottom .f_bottom_wrap .f_bottom_inner01 .f_logo {
  width: 200px;
  height: 40px;
  margin-bottom: 30px;
}

#footer .f_wrap .f_bottom .f_bottom_wrap .f_bottom_inner01 .f_logo a {
  display: block;
  width: 100%;
  height: 100%;
}

#footer .f_wrap .f_bottom .f_bottom_wrap .f_bottom_inner01 .f_logo a svg {
  fill: #000;
}

#footer .f_wrap .f_bottom .f_bottom_wrap .f_bottom_inner01 .f_bottom_menu {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

#footer .f_wrap .f_bottom .f_bottom_wrap .f_bottom_inner01 .f_bottom_menu li {
  font-size: 1.3rem;
}

#footer .f_wrap .f_bottom .f_bottom_wrap .f_bottom_inner01 .f_bottom_menu li a {
  display: block;
  line-height: 1;
  border-right: 1px solid #000;
  padding: 0 12px;
  letter-spacing: 0.15em;
}

#footer .f_wrap .f_bottom .f_bottom_wrap .f_bottom_inner01 .f_bottom_menu li a:hover {
  opacity: 1;
  color: #c02d28;
}

#footer .f_wrap .f_bottom .f_bottom_wrap .f_bottom_inner01 .f_bottom_menu li:first-of-type a {
  padding-left: 0;
}

#footer .f_wrap .f_bottom .f_bottom_wrap .f_bottom_inner01 .f_bottom_menu li:last-of-type a {
  padding-right: 0;
  border-right: none;
}

#footer .f_wrap .f_bottom .f_bottom_wrap .f_bottom_inner01 .copyright {
  font-size: 1.2rem;
  letter-spacing: .15em;
}

#footer .f_wrap .f_bottom .f_bottom_wrap .f_bottom_inner02 {
  margin-top: 10px;
}

#footer .f_wrap .f_bottom .f_bottom_wrap .f_bottom_inner02 .sns_blk {
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 34px;
}

#footer .f_wrap .f_bottom .f_bottom_wrap .f_bottom_inner02 .sns_blk li {
  width: auto;
  height: 20px;
}

#footer .f_wrap .f_bottom .f_bottom_wrap .f_bottom_inner02 .sns_blk li a {
  display: block;
  width: auto;
  height: 100%;
}

#footer .f_wrap .f_bottom .f_bottom_wrap .f_bottom_inner02 .sns_blk li a img {
  display: block;
  width: auto;
  height: 100%;
}

#footer .f_wrap .f_bottom .f_bottom_wrap .f_bottom_inner02 .f_btn {
  width: 180px;
  height: 45px;
}

#footer .f_wrap .f_bottom .f_bottom_wrap .f_bottom_inner02 .f_btn a {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: .1em;
  text-align: center;
  line-height: 45px;
  border: 1px solid #000;
}

#footer .f_wrap .f_bottom .f_bottom_wrap .f_bottom_inner02 .f_btn a:hover {
  background-color: #000;
  color: #fff;
  opacity: 1;
}

#footer .f_wrap_sp {
  display: none;
}

@media only screen and (min-width: 751px) and (max-width: 1220px) {
  #footer {
    margin-top: 4.6vw;
  }
  #footer .f_wrap {
    display: none;
  }
  #footer .f_wrap_sp {
    display: block;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner {
    width: 100%;
    transition-duration: .2s;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top {
    width: 100%;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_1st ul li {
    width: 100%;
    border-top: 1px solid #bbb;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_1st ul li a {
    display: block;
    width: 100%;
    height: 6.66667vw;
    padding: 0 2.66667vw;
    font-size: 1.86667vw;
    letter-spacing: .15em;
    line-height: 6.66667vw;
    font-weight: bold;
    position: relative;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_1st ul li a::after {
    transition-duration: .2s;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_1st ul li a .logo_colantotte {
    display: inline-block;
    width: 8.26667vw;
    height: 2.26667vw;
    margin-left: 1.73333vw;
    position: relative;
    top: 0.4vw;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_1st ul li a .logo_colantotte svg {
    width: 100%;
    height: 100%;
    fill: #000;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_1st ul li a .logo_resno {
    display: inline-block;
    width: 7.86667vw;
    height: 2.33333vw;
    margin-left: 1.06667vw;
    position: relative;
    top: 0.4vw;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_1st ul li a .logo_resno svg {
    width: 100%;
    height: 100%;
    fill: #fff;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_1st ul li.reg_link a::after {
    content: "";
    display: block;
    width: 1.73333vw;
    height: 1.06667vw;
    background-image: url(../../img/common/arrow_up_b.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    transform: rotate(90deg);
    position: absolute;
    top: calc(50% - (8/1500*100vw));
    right: 2.4vw;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_1st ul li.toggle_link > a::after {
    content: "";
    display: block;
    width: 1.73333vw;
    height: 1.06667vw;
    background-image: url(../../img/common/arrow_up_b.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    transform: rotate(180deg);
    position: absolute;
    top: calc(50% - (8/1500*100vw));
    right: 2.4vw;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_1st ul li.toggle_link > a.open::after {
    transform: rotate(0deg);
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_1st ul li.menu_resno a {
    background-color: #0086a2;
    color: #fff;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_1st ul li.menu_resno a::after {
    background-image: url(../../img/common/arrow_up_w.png);
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_2nd {
    display: none;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_2nd ul li a {
    padding-left: 5.33333vw;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_2nd ul li a.open {
    background-color: #eeeeee;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_2nd ul li a.open::after {
    transform: rotate(0deg);
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_3rd {
    display: none;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_3rd ul {
    background-color: #515151;
    display: flex;
    flex-wrap: wrap;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_3rd ul li {
    width: 50%;
    border-top: 1px solid #777;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_3rd ul li a {
    height: auto;
    color: #fff;
    font-size: 1.6vw;
    font-weight: normal;
    line-height: 3.4;
    padding: 0 2vw;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_3rd ul li:nth-of-type(1), #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_3rd ul li:nth-of-type(2) {
    border-top: none;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_3rd ul li:nth-of-type(odd) {
    border-right: 1px solid #777;
  }
  #footer .f_wrap_sp .f_bottom {
    width: 100%;
    background-color: #eeeeee;
    padding: 5.33333vw 0 5.46667vw;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .f_logo {
    width: 26.66667vw;
    height: 5.33333vw;
    margin: 0 auto 4vw;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .f_logo a {
    display: block;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .f_logo a svg {
    width: 100%;
    height: 100%;
    fill: #000;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .sns_blk {
    display: flex;
    justify-content: center;
    margin-bottom: 4.26667vw;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .sns_blk li {
    width: auto;
    height: 2.66667vw;
    padding: 0 2.46667vw;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .sns_blk li a {
    display: block;
    width: auto;
    height: 100%;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .sns_blk li a img {
    display: block;
    width: auto;
    height: 100%;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .f_bottom_menu {
    width: 100%;
    margin: 0 auto 2vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 5.33333vw;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .f_bottom_menu li {
    font-size: 1.46667vw;
    line-height: 2.68;
    white-space: nowrap;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .f_bottom_menu li a {
    display: block;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .f_btn {
    width: 16vw;
    height: 4vw;
    margin: 0 auto 2.66667vw;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .f_btn a {
    display: block;
    width: 100%;
    height: 100%;
    border: 1px solid #000;
    font-size: 1.46667vw;
    font-weight: bold;
    text-align: center;
    line-height: 4vw;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .copyright {
    width: 100%;
    padding: 0 2.66667vw;
    font-size: 1.13333vw;
    letter-spacing: .05em;
    line-height: 1.76;
    text-align: center;
  }
}

@media only screen and (max-width: 750px) {
  #footer {
    margin-top: 46px;
  }
  #footer .f_wrap {
    display: none;
  }
  #footer .f_wrap_sp {
    display: block;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner {
    width: 100%;
    transition-duration: .2s;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top {
    width: 100%;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_1st ul li {
    width: 100%;
    border-top: 1px solid #bbb;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_1st ul li a {
    display: block;
    width: 100%;
    height: 13.33333vw;
    padding: 0 5.33333vw;
    font-size: 3.73333vw;
    letter-spacing: .15em;
    line-height: 13.33333vw;
    font-weight: bold;
    position: relative;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_1st ul li a::after {
    transition-duration: .2s;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_1st ul li a .logo_colantotte {
    display: inline-block;
    width: 16.53333vw;
    height: 4.53333vw;
    margin-left: 3.46667vw;
    position: relative;
    top: 0.8vw;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_1st ul li a .logo_colantotte svg {
    width: 100%;
    height: 100%;
    fill: #000;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_1st ul li a .logo_resno {
    display: inline-block;
    width: 15.73333vw;
    height: 4.66667vw;
    margin-left: 2.13333vw;
    position: relative;
    top: 0.8vw;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_1st ul li a .logo_resno svg {
    width: 100%;
    height: 100%;
    fill: #fff;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_1st ul li.reg_link a::after {
    content: "";
    display: block;
    width: 3.46667vw;
    height: 2.13333vw;
    background-image: url(../../img/common/arrow_up_b.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    transform: rotate(90deg);
    position: absolute;
    top: calc(50% - (8/750*100vw));
    right: 4.8vw;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_1st ul li.toggle_link > a::after {
    content: "";
    display: block;
    width: 3.46667vw;
    height: 2.13333vw;
    background-image: url(../../img/common/arrow_up_b.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    transform: rotate(180deg);
    position: absolute;
    top: calc(50% - (8/750*100vw));
    right: 4.8vw;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_1st ul li.toggle_link > a.open::after {
    transform: rotate(0deg);
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_1st ul li.menu_resno a {
    background-color: #0086a2;
    color: #fff;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_1st ul li.menu_resno a::after {
    background-image: url(../../img/common/arrow_up_w.png);
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_2nd {
    display: none;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_2nd ul li a {
    padding-left: 10.66667vw;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_2nd ul li a.open {
    background-color: #eeeeee;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_2nd ul li a.open::after {
    transform: rotate(0deg);
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_3rd {
    display: none;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_3rd ul {
    background-color: #515151;
    display: flex;
    flex-wrap: wrap;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_3rd ul li {
    width: 50%;
    border-bottom: 1px solid #777;
    border-top: none;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_3rd ul li a {
    height: auto;
    color: #fff;
    font-size: 3.2vw;
    font-weight: normal;
    line-height: 3.4;
    padding: 0 4vw;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_3rd ul li:nth-of-type(1), #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_3rd ul li:nth-of-type(2) {
    border-top: none;
  }
  #footer .f_wrap_sp .f_menu_sp .h_slideright_menu_inner .slideright_menu_top .slideright_menu_3rd ul li:nth-of-type(odd) {
    border-right: 1px solid #777;
  }
  #footer .f_wrap_sp .f_bottom {
    width: 100%;
    background-color: #eeeeee;
    padding: 16.26667vw 0 16.66667vw;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .f_logo {
    width: 53.33333vw;
    height: 10.66667vw;
    margin: 0 auto 8vw;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .f_logo a {
    display: block;
    width: 100%;
    height: 100%;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .f_logo a svg {
    width: 100%;
    height: 100%;
    fill: #000;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .sns_blk {
    display: flex;
    justify-content: center;
    margin-bottom: 8.53333vw;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .sns_blk li {
    width: auto;
    height: 5.33333vw;
    padding: 0 4.93333vw;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .sns_blk li a {
    display: block;
    width: auto;
    height: 100%;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .sns_blk li a img {
    display: block;
    width: auto;
    height: 100%;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .f_bottom_menu {
    width: 78.4vw;
    margin: 0 auto 4vw;
    display: flex;
    flex-wrap: wrap;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .f_bottom_menu li {
    width: 50%;
    font-size: 2.93333vw;
    line-height: 2.68;
    white-space: nowrap;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .f_bottom_menu li a {
    display: block;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .f_btn {
    width: 32vw;
    height: 8vw;
    margin-left: 10.66667vw;
    margin-bottom: 4vw;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .f_btn a {
    display: block;
    width: 100%;
    height: 100%;
    border: 1px solid #000;
    font-size: 2.93333vw;
    font-weight: bold;
    text-align: center;
    line-height: 8vw;
  }
  #footer .f_wrap_sp .f_bottom .f_bottom_wrap .f_bottom_inner .copyright {
    width: 100%;
    padding: 0 5.33333vw;
    font-size: 2.26667vw;
    letter-spacing: .05em;
    line-height: 1.76;
  }
}
