#footer {
  margin-top: 46px;
  .f_wrap {
    a {
      transition-duration: .2s;
    }
    .f_menu {
      width: 1046px;
      margin: 0 auto 90px;
      display: flex;
      justify-content: space-between;
      padding: 50px 0 0 46px;
      border-top: 1px solid #000;
      a {
        &:hover {
          opacity: 1;
          color: #c02d28;
        }
      }
      .f_menu_blk {
        width: 180px;
        &:nth-of-type(2) {
          width: 200px;
        }
        &:nth-of-type(3) {
          width: 150px;
        }
        &:nth-of-type(4) {
          width: 140px;
        }
        &:nth-of-type(5) {
          width: 210px;
        }
        p {
          font-size: 1.8rem;
          font-weight: bold;
          letter-spacing: .15em;
          line-height: 1;
          margin-bottom: 14px;
        }
        ul {
          li {
            font-size: 1.4rem;
            line-height: 2;
            letter-spacing: .05em;
          }
        }
      }
    }
    .f_bottom {
      width: 100%;
      background-color: #eeeeee;
      padding: 30px 0 26px;
      .f_bottom_wrap {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .f_bottom_inner01 {
          .f_logo {
            width: 200px;
            height: 40px;
            margin-bottom: 30px;
            a {
              display: block;
              width: 100%;
              height: 100%;
              svg {
                fill: #000;
              }
            }
          }
          .f_bottom_menu {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 8px;
            li {
              font-size: 1.3rem;
              a {
                display: block;
                line-height: 1;
                border-right: 1px solid #000;
                padding: 0 12px;
                letter-spacing: 0.15em;
                &:hover {
                  opacity: 1;
                  color: #c02d28;
                }
              }
              &:first-of-type {
                a {
                  padding-left: 0;
                }
              }
              &:last-of-type {
                a {
                  padding-right: 0;
                  border-right: none;
                }
              }
            }
          }
          .copyright {
            font-size: 1.2rem;
            letter-spacing: .15em;
          }
        }
        .f_bottom_inner02 {
          margin-top: 10px;
          .sns_blk {
            width: 180px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 34px;
            li {
              width: auto;
              height: 20px;
              a {
                display: block;
                width: auto;
                height: 100%;
                img {
                  display: block;
                  width: auto;
                  height: 100%;
                }
              }
            }
          }
          .f_btn {
            width: 180px;
            height: 45px;
            a {
              display: block;
              width: 100%;
              height: 100%;
              font-size: 1.6rem;
              font-weight: bold;
              letter-spacing: .1em;
              text-align: center;
              line-height: 45px;
              border: 1px solid #000;
              &:hover {
                // border: 1px solid #fff;
                background-color: #000;
                color: #fff;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  .f_wrap_sp {
    display: none;
  }
}

@media only screen and (min-width: 751px) and (max-width: 1220px) {
#footer {
  margin-top: 46/1000*100vw;
  .f_wrap {
    display: none;
  }
  .f_wrap_sp {
    display: block;
    .f_menu_sp {
      .h_slideright_menu_inner {
        width: 100%;
        transition-duration: .2s;
        .slideright_menu_top {
          width: 100%;
          .slideright_menu_1st {
            ul {
              li {
                width: 100%;
                border-top: 1px solid #bbb;
                a {
                  display: block;
                  width: 100%;
                  height: 100/1500*100vw;
                  padding: 0 40/1500*100vw;
                  font-size: 28/1500*100vw;
                  letter-spacing: .15em;
                  line-height: 100/1500*100vw;
                  font-weight: bold;
                  position: relative;
                  &::after {
                    transition-duration: .2s;
                  }
                  .logo_colantotte {
                    display: inline-block;
                    width: 124/1500*100vw;
                    height: 34/1500*100vw;
                    margin-left: 26/1500*100vw;
                    position: relative;
                    top: 6/1500*100vw;
                    svg {
                      width: 100%;
                      height: 100%;
                      fill: #000;
                    }
                  }
                  .logo_resno {
                    display: inline-block;
                    width: 118/1500*100vw;
                    height: 35/1500*100vw;
                    margin-left: 16/1500*100vw;
                    position: relative;
                    top: 6/1500*100vw;
                    svg {
                      width: 100%;
                      height: 100%;
                      fill: #fff;
                    }
                  }
                }
                &.reg_link {
                  a {
                    &::after {
                      content: "";
                      display: block;
                      width: 26/1500*100vw;
                      height: 16/1500*100vw;
                      background-image: url(../../img/common/arrow_up_b.png);
                      background-repeat: no-repeat;
                      background-size: contain;
                      background-position: center center;
                      transform: rotate(90deg);
                      position: absolute;
                      top: calc(50% - (8/1500*100vw));
                      right: 36/1500*100vw;
                    }
                  }
                }
                &.toggle_link {
                  > a {
                    &::after {
                      content: "";
                      display: block;
                      width: 26/1500*100vw;
                      height: 16/1500*100vw;
                      background-image: url(../../img/common/arrow_up_b.png);
                      background-repeat: no-repeat;
                      background-size: contain;
                      background-position: center center;
                      transform: rotate(180deg);
                      position: absolute;
                      top: calc(50% - (8/1500*100vw));
                      right: 36/1500*100vw;
                    }
                    &.open {
                      &::after {
                        transform: rotate(0deg);
                      }
                    }
                  }
                }
                &.menu_resno {
                  a {
                    background-color: #0086a2;
                    color: #fff;
                    &::after {
                      background-image: url(../../img/common/arrow_up_w.png);
                    }
                  }
                }
              }
            }
          }
          .slideright_menu_2nd {
            display: none;
            ul {
              li {
                a {
                  padding-left: 80/1500*100vw;
                  &.open {
                    background-color: #eeeeee;
                    &::after {
                      transform: rotate(0deg);
                    }
                  }
                }
              }
            }
          }
          .slideright_menu_3rd {
            display: none;
            ul {
              background-color: #515151;
              display: flex;
              flex-wrap: wrap;
              li {
                width: 50%;
                border-top: 1px solid #777;
                a {
                  height: auto;
                  color: #fff;
                  font-size: 24/1500*100vw;
                  font-weight: normal;
                  line-height: 3.4;
                  padding: 0 30/1500*100vw;
                }
                &:nth-of-type(1),
                &:nth-of-type(2) {
                  border-top: none;
                }
                &:nth-of-type(odd) {
                  border-right: 1px solid #777;
                }
              }
            }
          }
        }
      }
    }
    .f_bottom {
      width: 100%;
      background-color: #eeeeee;
      padding: 80/1500*100vw 0 82/1500*100vw;
      .f_bottom_wrap {
        .f_bottom_inner {
          .f_logo {
            width: 400/1500*100vw;
            height: 80/1500*100vw;
            margin: 0 auto 60/1500*100vw;
            a {
              display: block;
              svg {
                width: 100%;
                height: 100%;
                fill: #000;
              }
            }
          }
          .sns_blk {
            display: flex;
            justify-content: center;
            margin-bottom: 64/1500*100vw;
            li {
              width: auto;
              height: 40/1500*100vw;
              padding: 0 37/1500*100vw;
              a {
                display: block;
                width: auto;
                height: 100%;
                img {
                  display: block;
                  width: auto;
                  height: 100%;
                }
              }
            }
          }
          .f_bottom_menu {
            width: 100%;
            margin: 0 auto 30/1500*100vw;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 0 80/1500*100vw;
            li {
              font-size: 22/1500*100vw;
              line-height: 2.68;
              white-space: nowrap;
              a {
                display: block;
              }
            }
          }
          .f_btn {
            width: 240/1500*100vw;
            height: 60/1500*100vw;
            margin: 0 auto 40/1500*100vw;
            a {
              display: block;
              width: 100%;
              height: 100%;
              border: 1px solid #000;
              font-size: 22/1500*100vw;
              font-weight: bold;
              text-align: center;
              line-height: 60/1500*100vw;
            }
          }
          .copyright {
            width: 100%;
            padding: 0 40/1500*100vw;
            font-size: 17/1500*100vw;
            letter-spacing: .05em;
            line-height: 1.76;
            text-align: center;
          }
        }
      }
    }
  }
}
}

@media only screen and (max-width: 750px) {
#footer {
  margin-top: 46px;
  .f_wrap {
    display: none;
  }
  .f_wrap_sp {
    display: block;
    .f_menu_sp {
      .h_slideright_menu_inner {
        width: 100%;
        transition-duration: .2s;
        .slideright_menu_top {
          width: 100%;
          .slideright_menu_1st {
            ul {
              li {
                width: 100%;
                border-top: 1px solid #bbb;
                a {
                  display: block;
                  width: 100%;
                  height: 100/750*100vw;
                  padding: 0 40/750*100vw;
                  font-size: 28/750*100vw;
                  letter-spacing: .15em;
                  line-height: 100/750*100vw;
                  font-weight: bold;
                  position: relative;
                  &::after {
                    transition-duration: .2s;
                  }
                  .logo_colantotte {
                    display: inline-block;
                    width: 124/750*100vw;
                    height: 34/750*100vw;
                    margin-left: 26/750*100vw;
                    position: relative;
                    top: 6/750*100vw;
                    svg {
                      width: 100%;
                      height: 100%;
                      fill: #000;
                    }
                  }
                  .logo_resno {
                    display: inline-block;
                    width: 118/750*100vw;
                    height: 35/750*100vw;
                    margin-left: 16/750*100vw;
                    position: relative;
                    top: 6/750*100vw;
                    svg {
                      width: 100%;
                      height: 100%;
                      fill: #fff;
                    }
                  }
                }
                &.reg_link {
                  a {
                    &::after {
                      content: "";
                      display: block;
                      width: 26/750*100vw;
                      height: 16/750*100vw;
                      background-image: url(../../img/common/arrow_up_b.png);
                      background-repeat: no-repeat;
                      background-size: contain;
                      background-position: center center;
                      transform: rotate(90deg);
                      position: absolute;
                      top: calc(50% - (8/750*100vw));
                      right: 36/750*100vw;
                    }
                  }
                }
                &.toggle_link {
                  > a {
                    &::after {
                      content: "";
                      display: block;
                      width: 26/750*100vw;
                      height: 16/750*100vw;
                      background-image: url(../../img/common/arrow_up_b.png);
                      background-repeat: no-repeat;
                      background-size: contain;
                      background-position: center center;
                      transform: rotate(180deg);
                      position: absolute;
                      top: calc(50% - (8/750*100vw));
                      right: 36/750*100vw;
                    }
                    &.open {
                      &::after {
                        transform: rotate(0deg);
                      }
                    }
                  }
                }
                &.menu_resno {
                  a {
                    background-color: #0086a2;
                    color: #fff;
                    &::after {
                      background-image: url(../../img/common/arrow_up_w.png);
                    }
                  }
                }
              }
            }
          }
          .slideright_menu_2nd {
            display: none;
            ul {
              li {
                a {
                  padding-left: 80/750*100vw;
                  &.open {
                    background-color: #eeeeee;
                    &::after {
                      transform: rotate(0deg);
                    }
                  }
                }
              }
            }
          }
          .slideright_menu_3rd {
            display: none;
            ul {
              background-color: #515151;
              display: flex;
              flex-wrap: wrap;
              li {
                width: 50%;
                border-bottom: 1px solid #777;
                border-top: none;
                a {
                  height: auto;
                  color: #fff;
                  font-size: 24/750*100vw;
                  font-weight: normal;
                  line-height: 3.4;
                  padding: 0 30/750*100vw;
                }
                &:nth-of-type(1),
                &:nth-of-type(2) {
                  border-top: none;
                }
                &:nth-of-type(odd) {
                  border-right: 1px solid #777;
                }
              }
            }
          }
        }
      }
    }
    .f_bottom {
      width: 100%;
      background-color: #eeeeee;
      padding: 122/750*100vw 0 125/750*100vw;
      .f_bottom_wrap {
        .f_bottom_inner {
          .f_logo {
            width: 400/750*100vw;
            height: 80/750*100vw;
            margin: 0 auto 60/750*100vw;
            a {
              display: block;
              width: 100%;
              height: 100%;
              svg {
                width: 100%;
                height: 100%;
                fill: #000;
              }
            }
          }
          .sns_blk {
            display: flex;
            justify-content: center;
            margin-bottom: 64/750*100vw;
            li {
              width: auto;
              height: 40/750*100vw;
              padding: 0 37/750*100vw;
              a {
                display: block;
                width: auto;
                height: 100%;
                img {
                  display: block;
                  width: auto;
                  height: 100%;
                }
              }
            }
          }
          .f_bottom_menu {
            width: 588/750*100vw;
            margin: 0 auto 30/750*100vw;
            display: flex;
            flex-wrap: wrap;
            li {
              width: 50%;
              font-size: 22/750*100vw;
              line-height: 2.68;
              white-space: nowrap;
              a {
                display: block;
              }
            }
          }
          .f_btn {
            width: 240/750*100vw;
            height: 60/750*100vw;
            margin-left: 80/750*100vw;
            margin-bottom: 30/750*100vw;
            a {
              display: block;
              width: 100%;
              height: 100%;
              border: 1px solid #000;
              font-size: 22/750*100vw;
              font-weight: bold;
              text-align: center;
              line-height: 60/750*100vw;
            }
          }
          .copyright {
            width: 100%;
            padding: 0 40/750*100vw;
            font-size: 17/750*100vw;
            letter-spacing: .05em;
            line-height: 1.76;
          }
        }
      }
    }
  }
}
}